<div class="mb-2">
  <label for="countries" class="block mb-1 text-sm font-medium text-[#797979] dark:text-white"
    >{{ label }} <span *ngIf="isRequired" class="text-red-600 dark:text-red-500">*</span></label
  >

  <select
    id="countries"
    [formControl]="form"
    (change)="onSelectChange()"
    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
  >
    <ng-container *ngFor="let option of options">
      <option [value]="option[bindValue]">{{ option[bindLabel] }}</option>
    </ng-container>
  </select>
</div>
